import React, {useEffect, useState} from 'react'
import { ShoppingContext } from './ShoppingContext';
import { getQuickOrderData, getRecentUserSearchData, getTopItems } from '../actions';
import { connect } from 'react-redux';

export const ShoppingContextProvider = (props) => {
    const { children, auth } = props
    const [facetsForFiltering, setFacetsForFiltering] = useState([])
    const [selectedSubscription, setSelectedSubscription] = useState({})
    const [userSpecificItems, setUserSpecificItems] = useState({})
    const [userSpecificItemsLoading, setUserSpecificItemsLoading] = useState(false)
    const [topItems, setTopItems] = useState([])
    const [userInfo, setUserInfo] = useState({})
    const [chatLog, setChatLog] = useState([])
  
    // Load from local storage when component mounts
    useEffect(() => {
      const storedFacets = localStorage.getItem('facetsForFiltering');
      if (storedFacets) {
        setFacetsForFiltering(JSON.parse(storedFacets));
      }

      const fetchUserSpecificItems = async () => {
        setUserSpecificItemsLoading(true)
        const quickOrderItems = await getQuickOrderData()
        const recentUserSearchItems = await getRecentUserSearchData()
        const quickOrderItemLists = quickOrderItems?.data
        const recentUserSearchItemsList = recentUserSearchItems?.data
        setUserSpecificItems({...quickOrderItemLists, ...recentUserSearchItemsList})
        setUserSpecificItemsLoading(false)
      }

      const fetchTopItems = async () => {
        const topItemsData = await getTopItems()
        setTopItems(topItemsData)
      }
  
      if(auth.user) { 
        fetchTopItems()
        fetchUserSpecificItems()
      }
    }, []);

    useEffect(() => {
      setUserInfo(auth.user)
    },[])
  
    // Save to local storage whenever facetsForFiltering changes
    // useEffect(() => {
    //   localStorage.setItem('facetsForFiltering', JSON.stringify(facetsForFiltering));
    // }, [facetsForFiltering]);
  
    return (
      <ShoppingContext.Provider
        value={{
          facetsForFiltering,
          setFacetsForFiltering,
          selectedSubscription,
          setSelectedSubscription,
          userSpecificItems,
          setUserSpecificItems,
          userSpecificItemsLoading,
          topItems,
          setUserSpecificItemsLoading,
          userInfo,
          setUserInfo,
          chatLog, 
          setChatLog
        }}
      >
        {children}
      </ShoppingContext.Provider>
    )
  };

  const mapStateToProps = state => {
    return {
      auth: state.auth,
    }
  }
  
export default connect(mapStateToProps)(ShoppingContextProvider)


  
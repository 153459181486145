import { pathOr } from 'ramda'
import { resolveReq, axios, scheduleExpiredTokenCheck } from '../utils/api'
import {
    SET_AUTH_ALERT,
    SET_USER_AUTH,
    TOGGLE_LOGIN_VIEW,
    SET_IS_PASSWORD_RESET_VALID,
    START_LOADER,
    STOP_LOADER,
    SET_IS_VALID_TOKEN,
    SET_RELATED_ACCOUNTS,
    SET_SELECTED_RELATED_ACCOUNT,
    GET_BUSINESS_TYPES,
    GET_CONTACT_ROLES,
    START_PUNCHOUT
} from './types'

import { getTokenDecoded } from '../utils/api'

import mixpanel from 'mixpanel-browser'
import aa from 'search-insights'
mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN)
//TOGGLE VIEW: login, forgotPassword, resetPassword

export const toggleView = (view) => {
    return { type: TOGGLE_LOGIN_VIEW, payload: view }
}

export const setAuthAlert = (payload = {}) => {
    return { type: SET_AUTH_ALERT, payload }
}

export const setLocalUser = (data) => {
    return async dispatch => {
        localStorage.setItem('token', data.token);
        if (data.isPunchout) localStorage.setItem('isPunchout', true);

        // Ensure Axios always sends the latest token
        axios.interceptors.request.use((config) => {
            config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
            return config;
        });

        const user = data.user || (await resolveReq(axios.get('/api/auth/currentUser'), true)).data;

        dispatch({ type: SET_USER_AUTH, payload: user });
        if (data.isPunchout) dispatch({ type: START_PUNCHOUT });

        // Inspectlet session tracking
        if (window.__insp) {
            window.__insp.push(['tagSession', { email: user.email, userId: user.id, username: user.username }]);
        }

        // Tidio live chat user identification
        document.tidioIdentify = {
            distinct_id: user.id,
            email: user.email,
            name: `${user.firstName} ${user.lastName}`,
        };

        scheduleExpiredTokenCheck();
        await setRelatedAccounts();
    };
};

export const loginUser = ({ password, username }) => {
    return async (dispatch) => {
      dispatch({ type: SET_AUTH_ALERT, payload: {} })
      dispatch({ type: START_LOADER, payload: 'userLoading' })
  
      try {
        const { data, error } = await resolveReq(
          axios.post('/api/auth/login', { password, username: username.trim() }),
          true
        )
  
        if (error) {
          dispatch({ type: SET_AUTH_ALERT, payload: { type: 'danger', message: error.message.error.message } })
        } else if (data && data.user) {
          const { user } = data
  
          if (process.env.NODE_ENV === 'production') {
            mixpanel.identify(user.id)
            mixpanel.people.set({
              name: user.username,
              '$email': user.email,
              account: user.account,
              permissions: user.permissions,
              'Last Login Date': new Date(),
            })
          }
  
          setLocalUser(data)(dispatch)
        } else {
          // Handle unexpected data structure or missing user data
          dispatch({ type: SET_AUTH_ALERT, payload: { type: 'danger', message: 'Unexpected error occurred. Please try again.' } })
        }
      } catch (err) {
        // Handle any unexpected errors during the request
        console.error('Login error:', err)
        dispatch({ type: SET_AUTH_ALERT, payload: { type: 'danger', message: 'Unexpected error occurred. Please try again later.' } })
      } finally {
        dispatch({ type: STOP_LOADER, payload: 'userLoading' })
      }
    }
  }
  

export const setCurrentUser = () => {
    return async dispatch => {
        dispatch({ type: START_LOADER, payload: 'accountLoading' })
        dispatch({ type: SET_AUTH_ALERT, payload: {} })
        const { error, data } = await resolveReq(axios.get('/api/auth/currentUser'), true)

        if (process.env.NODE_ENV === 'production'){        
            mixpanel.identify(data?.id)
            mixpanel.people.set({
                "name": data?.username,
                '$email': data?.email,
                'account': data?.account,
                'permissions': data?.permissions,
                'Last Login Date': new Date()
            })
            data?.id && aa('setUserToken', String(data?.id) )
        }

        if (error)
            dispatch({ type: SET_AUTH_ALERT, payload: { type: 'danger', message: 'Invalid username or password' } })
        else{
            dispatch({ type: SET_USER_AUTH, payload: data })
            data.isPunchout && dispatch({ type: START_PUNCHOUT })
        }
        dispatch({ type: SET_IS_VALID_TOKEN, payload: false })
        dispatch({ type: STOP_LOADER, payload: 'accountLoading' })
    }
}

export const setAccountLoadingFalse = () => ({ type: STOP_LOADER, payload: 'accountLoading' })

export const setRelatedAccounts = () => {
    return async dispatch => {
        const token = getTokenDecoded()
        const isParentLogin = pathOr(false, ['user', 'isParentLogin'], token)
        const selectedRelatedAccount = pathOr(null, ['user', 'account'], token)
        if (!isParentLogin) {
            dispatch({ type: SET_RELATED_ACCOUNTS, payload: [] })
            dispatch({ type: SET_SELECTED_RELATED_ACCOUNT, payload: null })
            return
        }

        dispatch({ type: START_LOADER, payload: 'retrievingRelatedAccounts' })
        const { error, data } = await resolveReq(axios.get('/api/auth/getRelatedAccounts'))
        if (error)
            return dispatch({ type: SET_AUTH_ALERT, payload: { type: 'danger', message: error.message } })

        const relatedAccounts = pathOr([], ['relatedAccounts'], data)
        dispatch({ type: SET_RELATED_ACCOUNTS, payload: relatedAccounts })
        dispatch({ type: SET_SELECTED_RELATED_ACCOUNT, payload: selectedRelatedAccount })
        dispatch({ type: STOP_LOADER, payload: 'retrievingRelatedAccounts' })
    }
}

export const forgotPasswordSubmit = ({ username }) => {
    return async dispatch => {
        dispatch({ type: SET_AUTH_ALERT, payload: {} })
        dispatch({ type: START_LOADER, payload: 'userLoading' })
        const { error } = await resolveReq(axios.post('/api/auth/createResetHash', { username: username.trim() }))

        if (error)
            dispatch({ type: SET_AUTH_ALERT, payload: { type: 'error', message: error?.message } })

        else {
            dispatch({ type: SET_AUTH_ALERT, payload: { type: 'success', message: 'Check your mail inbox' } })
            dispatch({ type: TOGGLE_LOGIN_VIEW, payload: 'login' })
        }

        dispatch({ type: STOP_LOADER, payload: 'userLoading' })
    }

}

export const requestAccessSubmit = ({ company, firstName, lastName, phone, email }) => {
    return async dispatch => {
        dispatch({ type: SET_AUTH_ALERT, payload: {} })
        dispatch({ type: START_LOADER, payload: 'userLoading' })
        const { error } = await resolveReq(axios.post('/api/auth/requestAccess', { company, firstName, lastName, phone, email: email.toLowerCase().trim() }))
        if (error)
            dispatch({ type: SET_AUTH_ALERT, payload: { type: 'danger', message: 'Failed to submit request' } })
        else {
            dispatch({ type: SET_AUTH_ALERT, payload: { type: 'success', message: 'Your request has been created' } })
            dispatch({ type: TOGGLE_LOGIN_VIEW, payload: 'login' })
        }

        dispatch({ type: STOP_LOADER, payload: 'userLoading' })
    }
}

export const validatePasswordReset = ({ hash, username }) => {
    return async dispatch => {
        dispatch({ type: SET_IS_PASSWORD_RESET_VALID, payload: null })
        const { data } = await resolveReq(axios.post('/api/auth/validatePasswordReset', { username, hash }))
        dispatch({ type: SET_IS_PASSWORD_RESET_VALID, payload: Boolean(data) })
    }
}

export const resetPassword = ({ hash, username, password, history, passwordMatch }) => {
    return async dispatch => {
        dispatch({ type: SET_AUTH_ALERT, payload: {} })
        dispatch({ type: START_LOADER, payload: 'userLoading' })

        const { error } = await resolveReq(axios.post('/api/auth/resetPassword', { username, hash, password, passwordMatch }))
        if (error)
            dispatch({ type: SET_AUTH_ALERT, payload: { type: 'danger', message: error.message } })
        else {
            dispatch({ type: SET_AUTH_ALERT, payload: { type: 'success', message: 'Password Reset! Logging you in' } })
            await loginUser({ username, password })(dispatch)
            history.push('/')
        }

        dispatch({ type: STOP_LOADER, payload: 'userLoading' })
    }
}

export const logout = (redirectToLogin = true) => {
    return async () => {
        await resolveReq(axios.get('/api/auth/logout'));
        localStorage.removeItem('token');

        // Clear Axios headers dynamically
        axios.interceptors.request.use((config) => {
            delete config.headers['Authorization'];
            return config;
        });

        // Remove Algolia Tracking Local Storage Variables
        Object.keys(localStorage)
            .filter(key => key.startsWith('itemclick'))
            .forEach(key => localStorage.removeItem(key));

        if (redirectToLogin) {
            window.location.href = '/login';
        }
    };
};

export const switchAccount = (accountToSwitchId) => {
    return async dispatch => {
        dispatch({ type: START_LOADER, payload: 'ekgLoading' })
        const { error, data } = await resolveReq(axios.post('/api/auth/switchAccount', { accountSwitchId: accountToSwitchId }))
        if (error) {
            console.error(error)
            dispatch({ type: STOP_LOADER, payload: 'ekgLoading' })
            return { error: true, success: false }
        }
        localStorage.setItem('token', data.token)

        axios.defaults.headers['Authorization'] = `Bearer ${data.token}`
        scheduleExpiredTokenCheck()
        dispatch({ type: SET_USER_AUTH, payload: data.user })
        dispatch({ type: STOP_LOADER, payload: 'ekgLoading' })
        return { error: false, success: true }
    }
}

export const getBusinessTypesandContactRoles = () => {
    return async dispatch => {
        dispatch({ type: START_LOADER, payload: 'retrievingBusinessTypesandContactRoles' })
        const { data, error } = await resolveReq(axios.get('/api/auth/getBusinessTypesandContactRoles'))
        const payload = pathOr([], ['data'], data)
        if (error)
            return console.log(error)
        dispatch({ type: GET_BUSINESS_TYPES, payload: payload.businessTypes })
        dispatch({ type: GET_CONTACT_ROLES, payload: payload.contactRoles })
        dispatch({ type: STOP_LOADER, payload: 'retrievingBusinessTypesandContactRoles' })
    }
}

export const registerAccountSubmit = async (formData) => {
    const { data, error } = await resolveReq(axios.post('/api/auth/createAccount', formData))
    if (error)
        return { error: true, data: error?.response?.data }
    return data
}
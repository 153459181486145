import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import moment from 'moment'
import { createStore, applyMiddleware } from 'redux'
import { CookiesProvider } from 'react-cookie'
import reduxThunk from 'redux-thunk'
import {
  refreshTokenMiddleware,
  axios,
  scheduleExpiredTokenCheck,
  getTokenDecoded,
  addChatWidget,
  addInspectlet,
} from './utils'
import * as serviceWorker from './serviceWorker'
import reducers from './reducers'
import App from './components/App/App'

import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"

import 'react-toastify/dist/ReactToastify.css'
import './assets/CSS/styles.scss'
import './components/Lists/ScrollTable/ScrollTable.css'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

Sentry.init({

  environment: process.env.NODE_ENV,
  dsn: process.env.REACT_APP_SENTRY_DSN_CLIENT,
  
  integrations: [new BrowserTracing()],

    /* 
		tracesSampleRate is the rate of transactions (TO DO: define transaction) that Sentry 
		will track in order to monitor the application's performance.
		
		A tracesSampleRate of 1.0 captures 100% of the transactions.  A recommended value
		to use is 0.1 (i.e. 10%).

    */

  tracesSampleRate: 1.0,
})

//Development Only
const isSandbox = process.env.NODE_ENV !== 'production'
if (isSandbox) window.axios = axios

const ckName = isSandbox ? 'dealmed-sandbox' : 'dealmed-production'
window.setAccount(process.env.REACT_APP_CARDKNOX_PUBLIC_KEY, ckName, '0.1.2')

const createStoreAndRender = (initialState = {}) => {

    refreshTokenMiddleware(axios)
    scheduleExpiredTokenCheck()
    const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore)
    const store = createStoreWithMiddleware(reducers, initialState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

    ReactDOM.render(
      <CookiesProvider>
     
          <Provider store={store}>
            <App />
          </Provider>
      </CookiesProvider>,
      document.getElementById('root'),
    )
  
    if (window.Cypress) {
      window.Cypress.store = store
    }
  
    serviceWorker.unregister()
}

const decodedToken = getTokenDecoded() || {}; // Ensure it's an object
const { exp, user } = decodedToken;

const isValidToken = exp && exp > moment().unix()

if (user)
  document.tidioIdentify = {
    distinct_id: user.id,
    email: user.email,
    name: user.firstName + ' ' + user.lastName,
  }

addChatWidget(user)

if ((window.origin || window.location.hostname).includes('dealmed')) addInspectlet()

if (window.__insp && user)
  window.__insp.push([
    'tagSession',
    { email: user.email, userId: user.id, username: user.username },
  ])

const INITAL_STATE_AUTH = {
  viewLogin: 'login',
  isValidToken,
  isPasswordResetValid: null,
}

createStoreAndRender({ auth: INITAL_STATE_AUTH })
